<template>
<div class="wrapper">
  <h1>AVISO LEGAL</h1>
      <div class="content">
      <p>El contenido de esta página web es propiedad de la sociedad Solutz Energía S.L. <br>Para dar cumplimiento a lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, acontinuación indicamos los datos de la información general de nuestro sitio web www.Solutz.com:</p>
    <p>
        <strong>Titular:</strong> Solutz Energía, S.L. (en adelante “Solutz”)<br>
        <strong>CIF:</strong> B- 88571518 <br>
        <strong>Dirección:</strong> Calle Principe de Viana 3, 28023, Madrid.<br>
        <strong>Email:</strong> pedro@solutz.com<br>
        <strong>Inscripción:</strong> Inscrita en el Registro Mercantil de Madrid al Tomo 40207, Folio 160, Inscripción 1 con hoja M-714470.<br>
    </p>
    <h2>Condiciones de uso</h2>
    <p>
        Las presentes Condiciones tienen por objeto regular el uso de esta página web que Solutz pone a disposición del público en la presente URL. <br>
La utilización de la página web por un tercero le atribuye la condición de Usuario y, supone la aceptación plena por dicho Usuario, de todas y cada una de las condiciones que se incorporan en el presente Aviso Legal. <br>
El titular del Web puede ofrecer a través de esta página web, servicios o productos que podrán encontrarse sometidos a unas condiciones particulares propias que, según los casos, sustituyen, completan y/o modifican las presentes Condiciones, y sobre las cuales se informará al Usuario en cada caso concreto. <br>
El acceso a los servicios de Solutz a través de la dirección URL http://www.Solutz.com (en adelante, la “Web”) o por otros medios digitales, soportes o dispositivos habilitados por Solutz implica tu aceptación de estas Condiciones de Uso, ya seas un mero visitante, usuario registrado o cliente de los servicios de Solutz. Por ello, te invitamos a que leas estas Condiciones de Uso y si tienes alguna duda, contactes con nosotros enviando un correo electrónico a pedro@Solutz.com

    </p>
    <h2>Acceso a la Web y registro</h2>
    <p>El acceso a la Web es libre y gratuito. La seguridad en el acceso de usuarios registrados a la Web se articula a través de los sistemas de conexión de terceros de que se dispone en la Plataforma (p. ej. Facebook Connect), en cuyo caso Solutz te recomienda que revises las condiciones de uso y política de privacidad de dichos sistemas, no siendo Solutz responsable de la seguridad de los mismos.</p>
    <h2>Uso correcto del sitio web</h2>
    <p>El usuario se compromete a utilizar la página web, los contenidos y servicios de conformidad con la Ley, el presente Aviso Legal, las buenas costumbres y el orden público. Del mismo modo, el usuario se obliga a no utilizar la página web o los servicios que se presten a través de él con fines o efectos ilícitos o contrarios al contenido del presente Aviso Legal, lesivos de los intereses o derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o deteriorar la página web o sus servicios, o impedir un normal disfrute del sitio web por otros usuarios.</p>
    <p>Asimismo, el usuario se compromete expresamente a no destruir, alterar, inutilizar o, de cualquier otra forma, dañar los datos, programas o documentos electrónicos y demás que se encuentren en la presente página web.</p>
    <p>El usuario se compromete a no obstaculizar el acceso de otros usuarios al servicio de acceso mediante el consumo masivo de los recursos informáticos a través de los cuales el titular del sitio web presta el servicio, así como realizar acciones que dañen, interrumpan o generen errores en dichos sistemas.</p>
    <p>El usuario se compromete a no introducir programas, virus, macros, applets, controles ActiveX o cualquier otro dispositivo lógico o secuencia de caracteres que causen o sean susceptibles de causar cualquier tipo de alteración en los sistemas informáticos del titular del sitio web o de terceros.</p>
    <p>Para la utilización de nuestro sitio web es necesario la utilización de cookies. Las cookies se utilizan con la finalidad de añadir funcionalidad a la web, nunca para recabar información del usuario si usted lo desea puede configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la instalación de cookies en su disco duro. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.</p>
    <h2>PROHIBICIONES Y RESPONSABILIDADES</h2>
    <p>No está permitido y, por tanto, sus consecuencias serán de tu exclusiva responsabilidad, el acceso o la utilización de la Plataforma con fines ilegales o no autorizados, con o sin finalidad económica, y, más específicamente,   de una manera enunciativa y no limitativa, queda prohibido:</p>
    <p>Usar la Plataforma en forma alguna que pueda provocar daños, interrupciones, ineficiencias o defectos en su funcionalidad o en el ordenador de un tercero;</p>
    <p>Usar la Plataforma para la transmisión, la instalación o la publicación de cualquier virus, código malicioso u otros programas o archivos perjudiciales o de cualquier material de carácter difamatorio, ofensivo, racista, vulgar, denigrante, pornográfico, o de naturaleza obscena o amenazadora o que pueda causar molestia a cualquier persona;</p>
    <p>Usar la Plataforma para recoger datos de carácter personal de otros usuarios;</p>
    <p>Usar la Plataforma de manera que constituya una vulneración de los derechos de Solutz o de cualquier tercero (incluyendo derechos de propiedad intelectual);</p>
    <p>Registrarte a través de la Plataforma con una identidad falsa, suplantado a terceros o utilizando un perfil o realizando cualquier otra acción que pueda confundir a otros usuarios sobre la identidad del origen de un mensaje;</p>
    <p>Usar la Plataforma para transmitir material con fines publicitarios o de promoción, incluidos spam, correos electrónicos en cadena o similares;</p>
    <p>Acceder sin autorización a cualquier sección de la Plataforma, a otros sistemas o redes conectadas a la Web, a ningún servidor de Solutz ni a los servicios ofrecidos a través de la Plataforma, por medio de pirateo o falsificación, extracción de contraseñas o cualquier otro medio ilegítimo;</p>
    <p>Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación de la Plataforma o de cualquier red conectada a la misma, o las medidas de seguridad o protección inherentes a los contenidos audiovisuales ofrecidos en la Plataforma;</p>
    <p>Llevar a cabo alguna acción que provoque una saturación desproporcionada o innecesaria en la infraestructura de la Web o en los sistemas o redes de Solutz, así como en los sistemas y redes conectados a la Plataforma;</p>
    <p>Usar la Plataforma de forma ilegal, en contra de la buena fe, la moral o el orden público.</p>
    <p>Impedir el normal desarrollo de un evento, concurso, promoción o cualquier otra actividad disponible a través de la Plataforma o cualesquiera de sus funcionalidades, ya sea alterando o tratando de alterar, ilegalmente o de cualquier otra forma, el acceso, participación o funcionamiento de aquéllos, o falseando el resultado de los mismos y/ o utilizando métodos de participación fraudulentos, mediante cualquier procedimiento, y/o a través de cualquier práctica que atente o vulnere en modo alguno las presentes Condiciones de Uso.</p>
    <p>El incumplimiento de cualquiera de las anteriores obligaciones por el usuario, podrá llevar aparejada la adopción por Solutz de las medidas necesarias, pudiendo llegar a la eliminación o bloqueo de la cuenta del usuario infractor.</p>
    <h2>Propiedad Intelectual</h2>
    <p>Todos los contenidos que se recogen en www.solutz.com, salvo que se indique lo contrario, son titularidad exclusiva de Solutz y, con carácter enunciativo, que no limitativo, el diseño gráfico, los logotipos, los textos, los gráficos, las ilustraciones, y demás elementos que aparecen en la página web.</p>
    <p>Igualmente, todos los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en el Web están protegidos por la Ley.</p>
    <p>El titular de la página web no concede ningún tipo de licencia o autorización de uso personal al usuario sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho relacionado con su sitio web y los servicios ofrecidos en la misma, sin el permiso previo y por escrito de Solutz.</p>
    <p>Por ello, el usuario reconoce que la reproducción, distribución, comercialización, transformación, y en general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de esta página web constituye una infracción de los derechos de propiedad intelectual y/o industrial del titular de la página web o del titular de los mismos.</p>
    <h2>USUARIOS</h2>
    <p>El acceso y/o uso del este Portal de nombre http://www.solutz.com, atribuye la condición de Usuario, que acepta, desde dicho acceso y/o uso, las Condiciones de Uso aquí reflejadas.</p>
    <p>PROPIEDAD INTELECTUAL</p>
    <p> Solutz, por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, etc.), titularidad de Solutz o bien sus licenciantes. Todos los derechos reservados. En virtud de los dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de Solutz El Usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Solutz</p>
    <p>Podrá visualizar los elementos del Portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El Usuario deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en la página de Solutz</p>
    <h2>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD  EN LOS CONTENIDOS DE LA WEB</h2>
    <p>Solutz no se hace responsable de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo errores u omisiones en los contenidos, falta de disponibilidad del Portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.</p>
    <p>Por supuesto, estamos realizando nuestros mayores esfuerzos para asegurar la continuidad, seguridad y calidad de la Plataforma en base al estado actual de la técnica. Sin embargo, no podemos garantizar la disponibilidad permanente y la continuidad del funcionamiento de la Plataforma, la ausencia de virus u otros elementos que puedan producir alteraciones en tu sistema informático, ni la fiabilidad, utilidad, veracidad, exactitud, exhaustividad y actualidad de la información contenida en la Web, y por tanto no nos hacemos responsables en caso de que cualquiera de estas circunstancias ocurra.</p>
    <h2>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD  EN LOS PRODUCTOS</h2>
    <p>Los productos intercambiados a través de http://www.solutz.com están sujetos a los acuerdos establecidos por los usuarios participantes en dicho trueque. Por tanto Solutz, declina cualquier responsabilidad que se derive de dicha transacción.</p>
    <h2>MODIFICACIONES</h2>
    <p>Solutz, se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su Portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su Portal.</p>
    <h2>ENLACES</h2>
    <p>En el caso de que en http://www.solutz.com se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, Solutz no ejercerá ningún control sobre dichos sitios y contenidos. En ningún caso Solutz asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>
    <p>En este sentido, si tuvieras conocimiento de la ilicitud de actividades desarrolladas a través de páginas web de terceros con link en la Web, deberás comunicarlo inmediatamente a Solutz a los efectos de que se proceda a deshabilitar el link de acceso a la misma.</p>
    <p>En el caso de enlaces en otras páginas web con destino a Solutz, si lo que te gustaría hacer es poner links en tu página web que dirijan a la Web, recuerda que debes cumplir con las siguientes condiciones:</p>
    <p>El link únicamente permitirá el acceso a la Web pero no podrá reproducir la Web o partes de la misma de ninguna forma;</p>
    <p>No está permitido establecer links con áreas de la Plataforma distintas de las zonas públicas, es decir aquellas a las que puedes acceder aunque no estés registrado a través de la Plataforma;</p>
    <p>No está permitido crear un browser ni un border environment sobre las secciones de la Plataforma, ni de ninguna otra forma podrá modificarse la Plataforma;</p>
    <p>No está permitido realizar manifestaciones o indicaciones falsas o inexactas o incorrectas sobre la Plataforma y/o en particular, declarar o dar a entender que Solutz ha autorizado el link o que ha supervisado o asumido de cualquier forma los contenidos o servicios ofrecidos o puestos a disposición en la página web en la que se establece el link;</p>
    <p>Salvo aquellos signos que formen parte del mismo link, la página web en la que se establezca el link no podrá contener ninguna marca, logotipo, eslogan u otros signos distintivos de Solutz;</p>
    <p>La página web en la que se establezca el link no contendrá informaciones o contenidos ilícitos, contrarios a la moral y buenas costumbres generalmente aceptadas y al orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros, incluidos los derechos de propiedad intelectual e industrial y/o el derecho al honor, a la intimidad personal o familiar o a la propia imagen o de cualquier otro derecho.</p>
    <p>Solutz no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos enlaces con destino a la Web. En este sentido, Solutz no asume ningún tipo de responsabilidad por cualquier aspecto relativo a la página web que establece ese enlace, en concreto, a título enunciativo y no limitativo, sobre su funcionamiento acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos en general</p>
    <h2>PUBLICIDAD</h2>
    <p>Solutz, se compromete a través de este medio a no realizar publicidad engañosa. A estos efectos, por lo tanto, no serán considerados como publicidad engañosa los errores formales o numéricos que puedan encontrarse a lo largo del contenido de las distintas secciones del sitio web, producidos como consecuencia de un mantenimiento y/o actualización incompleta o defectuosa de la información contenida en estas secciones. Solutz como consecuencia de lo dispuesto en este apartado, se compromete a corregirlo tan pronto como tenga conocimiento de dichos errores.</p>
    <h2>DERECHO DE EXCLUSIÓN</h2>
    <p>Solutz se reserva el derecho a denegar o retirar el acceso a Portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones de Uso.</p>
    <h2>GENERALIDADES</h2>
    <p>Solutz perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su Portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>
    <p>Puedes contactar con nosotros enviando un correo electrónico a pedro@solutz.com. Si incumples estas Condiciones de Uso, Solutz podrá suspender o cancelar tu perfil automáticamente sin previo aviso, y, en ningún caso, tal suspensión o cancelación te daría derecho a indemnización alguna. A estos efectos, te informamos que Solutz podrá poner en conocimiento y colaborar oportunamente con las autoridades policiales y judiciales competentes si detectase cualquier infracción de la legislación vigente o si tuviera sospecha de la comisión de algún delito.</p>
    <p>Los encabezamientos de las distintas cláusulas son sólo informativos, y no afectarán, calificarán o ampliarán la interpretación de las Condiciones de Uso.</p>
    <p>En el caso de existir discrepancia entre lo establecido en las presentes Condiciones de Uso y las condiciones particulares de cada servicio específico, prevalecerá lo dispuesto en éstas últimas.</p>
    <p>El no ejercicio o ejecución por parte de Solutz de cualquier derecho o disposición contenida en estas Condiciones de Uso no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por escrito por su parte.</p>
    <p>En el caso de que cualquier disposición o disposiciones de estas Condiciones de Uso fuera(n) considerada(s) nula(s) o inaplicable(s), en su totalidad o en parte, por cualquier Juzgado,  Tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará a las otras disposiciones de estas Condiciones de Uso</p>
    <h2>MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</h2>
    <p>Solutz podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá en función de su exposición  y estarán  vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
    <h2>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
    <p>La relación entre Solutz y el Usuario se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y Tribunales de la ciudad de Madrid.</p>


  </div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  mounted () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    .content{
        text-align: left;
    }
}
</style>
